import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import Loading from './components/utils/Loading';
import MainApp from './components/main_screens/main_app';
import Login from './components/main_screens/login';
import AgeVerification from './components/main_screens/AgeVerification';
import RewardViewer from './components/main_screens/prize_viewer';
import ImageViewer from './components/main_screens/image_viewer';
import {base} from './base';
import ReactGA from 'react-ga';
import FallbackLocalStorage from "fallback-local-storage";
import RedeemedViewer from "./components/main_screens/RedeemedViewer";
let appStorage;

if(process.env.NODE_ENV === "production"){
  ReactGA.initialize(process.env.REACT_APP_GA_KEY);
}

function logPageView(path) {
    path = path || window.location.pathname;
    if(process.env.NODE_ENV === "production"){
        ReactGA.set({ page: path });
        ReactGA.pageview(path);
    }
}

function AuthenticatedRoute({component: Component, authenticated, ...rest}) {
    return (
        <Route
            {...rest}
            render={(props) => {
                if(authenticated === true){
                    return (
                        <Component {...props} {...rest} />
                    )
                } else {
                    return (
                        <Redirect to={{pathname: '/login', state: {from: props.location}}} />
                    )
                }
            }} />
    )
}

class App extends Component {
    constructor(props) {
        super(props);
        this.setCurrentUser = this.setCurrentUser.bind(this);
        let userEmail;
        try {
            userEmail = appStorage.getItem('userEmail') || false;
        } catch (e) {
            userEmail = false
        }
        this.state = {
            authenticated: !!userEmail,
            variables: {},
            loading: true,
            passedEmail: false
        }
    }

    componentDidMount() {
        this.getTenantVariables();
    }

    setCurrentUser() {
        const userEmail = appStorage.getItem('userEmail') || false;
        this.setState({
            authenticated: !!userEmail
        })
    }

    getTenantVariables(){
        this.tenantVariablesRef = base.listenTo(`tenantVariables`, {
            context: this,
            state: 'tenantVariables',
            then(variables){
                document.title = variables.pageTitle || "Hidden Ball";
                this.setState({
                    authenticated: false,
                    variables: variables,
                    loading: false
                })
                this.setUpStorage(variables);
            }
        });
    }

    componentWillUnmount() {
      base.removeBinding(this.tenantVariablesRef);
    }

    setPassedEmail(isSet=true){
      this.setState({
          passedEmail: isSet
      })
    }

    setUpStorage(variables){
        const keepFansLoggedIn = variables.keepFansLoggedIn || false;
        if (FallbackLocalStorage.getStorage().includes("sessionStorage") && !keepFansLoggedIn) {
            // Here we don't have any problems
            // with writing to `window.localStorage`
            appStorage = window.sessionStorage;
        } else if(FallbackLocalStorage.getStorage().includes("localStorage") && keepFansLoggedIn) {
            appStorage = window.localStorage;
        } else {
            // Looks like we have some troubles.
            // Browser has disable `window.localStorage` support.
            // Or browser is in `Private Mode`
            // which disables localStorage completely.
            appStorage = new FallbackLocalStorage();
        }
    }

    checkForAgeGate(tenantVariables){
        const variable_age_boolean = tenantVariables.collectBirthday || false;
        if(!variable_age_boolean){
            return true
        }
        const formBirthday = tenantVariables.formBirthday || false;
        let variable_age = tenantVariables.allowedAge || 21;
        variable_age = parseInt(variable_age, 10);
        let user_age;
        try {
            user_age = appStorage.getItem('verifiedAge')
        } catch (e) {
            user_age = false;
        }
        if(isNaN(parseInt(user_age))){
            user_age = false
        } else {
            user_age = parseInt(user_age, 10)
        }
        return (user_age && user_age >= variable_age) || formBirthday;
    }

    render() {
        if (this.state.loading === true) {
            return (
                <Loading loading={this.state.loading}/>
            )
        }
        return (
            <BrowserRouter>
              <Switch>
                <Route
                    exact
                    path="/login"
                    render={(props) => {
                        return <Login
                            authenticated={this.state.authenticated}
                            appStorage={appStorage}
                            loadPage={() => logPageView()}
                            setCurrentUser={this.setCurrentUser}
                            variables={this.state.variables}
                            setPassed={(setPassed)=>this.setPassedEmail(setPassed)}
                            checkForAgeGate={this.checkForAgeGate}
                            {...props}
                        />
                    }}
                />

                <AuthenticatedRoute
                  exact
                  path="/"
                  appStorage={appStorage}
                  authenticated={this.state.authenticated}
                  variables={this.state.variables}
                  passedEmail={this.state.passedEmail}
                  loadPage={() => logPageView()}
                  checkForAgeGate={this.checkForAgeGate}
                  component={MainApp} />

                {/*<Route*/}
                {/*    exact*/}
                {/*    path="/"*/}
                {/*    render={(props) => {*/}
                {/*        return <MainApp variables={this.state.variables} passedEmail={this.state.passedEmail} checkForAgeGate={this.checkForAgeGate} {...props} />*/}
                {/*    }}*/}
                {/*/>*/}

                  <Route
                      exact
                      path="/prizeviewer"
                      render={(props) => {
                          return <RewardViewer appStorage={appStorage} loadPage={() => logPageView()} {...props} />
                      }}
                  />

                  <Route
                      exact
                      path="/imageviewer"
                      render={(props) => {
                          return <ImageViewer appStorage={appStorage} loadPage={() => logPageView()} {...props} />
                      }}
                  />

                <Route
                    exact
                    path="/age_gate"
                    render={(props) => {
                        return <AgeVerification appStorage={appStorage} loadPage={() => logPageView()} variables={this.state.variables} checkForAgeGate={this.checkForAgeGate} {...props} />
                    }}
                />

                  <Route
                      exact
                      path="/redeemviewer"
                      render={(props) => {
                          return <RedeemedViewer appStorage={appStorage} loadPage={() => logPageView()} stringConstants={this.state.languageConstants} tenantVariables={this.state.variables} {...props} />
                      }}
                  />

                <Redirect to='/login'/>
              </Switch>
            </BrowserRouter>
        )
    }
}

export default App;
